import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FeatureGrid = ({ children }) => (
  <ul className="grid gap-6 sm:row-gap-12 sm:gap-8 md:gap-12 lg:gap-16 xl:gap-24 grid-cols-1 sm:grid-cols-2">
    {children}
  </ul>
);

export const Feature = ({
  heading,
  headingClassName,
  iconName,
  children,
  className,
}) => (
  <li className={className}>
    <h4
      className={`font-medium text-lg md:text-xl lg:text-2xl mb-2 ${headingClassName}`}
    >
      <FontAwesomeIcon
        icon={["far", iconName]}
        className="md:block mr-2 md:mb-2 lg:text-3xl"
      />
      {heading}
    </h4>
    <div className="leading-relaxed lg:text-lg xl:text-xl">{children}</div>
  </li>
);

export default FeatureGrid;
